// extracted by mini-css-extract-plugin
export var Bold = "Histoire-module--Bold--0ImDb";
export var ContactLink = "Histoire-module--ContactLink--wGI9s";
export var Contactlink = "Histoire-module--Contactlink--mXDfS";
export var DevelopmentWrapper = "Histoire-module--DevelopmentWrapper--vl-Q1";
export var LegalMentionsWrapper = "Histoire-module--LegalMentionsWrapper--1GKqR";
export var Link = "Histoire-module--Link--wC-if";
export var NameValueWrapper = "Histoire-module--NameValueWrapper--OJtNZ";
export var RowWrapper = "Histoire-module--RowWrapper--qSZ-0";
export var Wrapper = "Histoire-module--Wrapper--tkZAK";
export var link = "Histoire-module--link--LPwMF";